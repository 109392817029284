@import '../../../libs/shared//mi-ui-global-styles/src/styles/base/variables/_variables.scss';

html {
  scroll-behavior: smooth;
}

main {
  display: block;
  overflow: hidden;
  @media (min-width: 767px) and (max-width: 1199px) {
    margin-bottom: 6rem;
  }
  &:not(:has(.social-icons)) {
    @media screen and (max-width: 767px) {
      margin-bottom: 4rem;
    }
  }
}

[dir='rtl'] .icon-arrow-left:before,
[dir='rtl'] .icon-arrow-right-cropped:before,
[dir='rtl'] .icon-arrow-right:before,
[dir='rtl'] .icon-backward-arrow:before,
[dir='rtl'] .icon-forward-arrow:before,
[dir='rtl'] .icon-long-arrow-back:before,
[dir='rtl'] .icon-long-arrow-forward:before {
  transform: scaleX(-1);
}

[dir='rtl'] .icon-arrow-left-cropped,
[dir='rtl'] .icon-forward-arrow-cropped {
  transform: scaleX(1);
}

//Local header component
.global-nav {
  position: relative;
  height: 3.75rem;
  a[class*='m-button']:focus-visible,
  button:focus-visible span.icon-clear:before {
    outline: none;
  }
}
.m-global-nav-top {
  .m-global-nav-top-right {
    position: static;
  }
  .m-global-nav-top-left {
    position: static;
  }
}

.m-global-nav {
  &.sticky {
    position: fixed;
    top: 0;
  }
  &-language-selector {
    .sr-only {
      color: $base-20;
    }
  }
  &-mobile-close {
    @media screen and (max-width: 1199px) {
      // @todo - Revert !important change once the defect is fixed by the loyalty team
      // OSD-4827 :: Close button not coming in the mobile/iPad device
      position: fixed !important;
    }
  }
}

.menu-modal-open {
  @media screen and (max-width: 1199px) {
    overflow: hidden;
  }
}

//internet bar component
.m-global-nav-top-center {
  a:hover {
    color: $base-20;
    font-weight: normal;
  }
  .t-program-logo-xs {
    font-size: 1.5rem;
  }
  .t-program-logo-cn-s {
    font-size: 2.25rem;
    &::before {
      color: $base-20;
    }
  }
}
.m-global-nav-main-menu {
  .t-program-logo-xs,
  .t-program-logo-cn-s {
    margin-bottom: 1.5rem;
    color: $base-20;
    font-weight: normal;
    font-size: 2rem;
  }
  .t-program-logo-cn-s {
    font-size: 3rem;
    &::before {
      color: $base-20;
    }
  }
}
//ppv5 preconnect footer
.ppv5-preconnect {
  .mt-copyright-component {
    padding: 0;
    & .mt-copyright-component__copyright {
      border: none;
      padding-top: 0;
    }
  }
}

.amenities-list {
  padding-top: 2.5rem;
}

.nearby-outlet__cardList-card_content-section p {
  word-wrap: break-word;
}

.trc-nearby-outlet .nearby-outlet__filters .m-button-primary {
  border-radius: 0;
  color: var(--t-brand-fg-color);
}

.local-footer.footer-dynamic .content-container .footer-cookies-info,
.local-footer.footer-dynamic .content-container .icon-arrow-right {
  display: none;
}
