/// @group fonts
///

/* Font family variations
    ************************* */
$font-family-swiss: Swiss-721 !default;
$font-swiss: $font-family-swiss !default;
$font-swiss-condensed: SwissCondensed !default;
$font-miIcons: MiIcons !default;
$font-brandLogoIcons: BrandLogoIcons !default; /*Brand icons with "by Marriott". These brand Icons are used in uni nav brand portfolio card, search results, brand filters,favs page*/
$font-portfolioLogoIcons: PortfolioLogoIcons !default; /*These font icons are ONLY used in the brand ribbon.*/
$font-family-videoicons: VideoIcons !default;

/* Language Specific Font Variables
   ***********************************/
$font-family-pl: 'UniversNextW1G,Helvetica,Arial,sans-serif';
$font-family-ar: JannaLT;
$font-family-ko: NotoSansKR;
$font-family-ja: NotoSansJP;
$font-family-tc: NotoSansTC;
$font-family-sc: NotoSansSC;
$font-family-ru: UniversLTCYR;
$font-family-he: Swiss721Hebrew;

:root {
  --t-base-font-family: #{$font-swiss};
}
:lang(pl-PL),
:lang(vi),
:lang(id),
:lang(da),
:lang(nl),
:lang(el),
:lang(th),
:lang(tr-TR) {
  --t-base-font-family: #{$font-family-pl};
  --t-font-family: #{$font-family-pl};
  --t-title-font-family: #{$font-family-pl};
}
:lang(ar-AE),
:lang(ar) {
  --t-base-font-family: #{$font-family-ar};
  --t-font-family: #{$font-family-ar};
  --t-title-font-family: #{$font-family-ar};
}
:lang(ko-KR) {
  --t-base-font-family: #{$font-family-ko};
  --t-font-family: #{$font-family-ko};
  --t-title-font-family: #{$font-family-ko};
}
:lang(ja-JP) {
  --t-base-font-family: #{$font-family-ja};
  --t-font-family: #{$font-family-ja};
  --t-title-font-family: #{$font-family-ja};
}
:lang(zh-CN) {
  --t-base-font-family: #{$font-family-sc};
  --t-font-family: #{$font-family-sc};
  --t-title-font-family: #{$font-family-sc};
}
:lang(zh-TW) {
  --t-base-font-family: #{$font-family-tc};
  --t-font-family: #{$font-family-tc};
  --t-title-font-family: #{$font-family-tc};
}
:lang(ru-RU) {
  --t-base-font-family: #{$font-family-ru};
  --t-font-family: #{$font-family-ru};
  --t-title-font-family: #{$font-family-ru};
}
:lang(he) {
  --t-base-font-family: #{$font-family-he};
  --t-font-family: #{$font-family-he};
  --t-title-font-family: #{$font-family-he};
}

/* Font size variations
    *********************** */
$font-xs: 0.75rem !default;
$font-s: 0.875rem !default;
$font-m: 1rem !default;
$font-l: 1.125rem !default;
$font-xl: 1.25rem !default;
$font-xxl: 1.375rem !default;
$font-xxxl: 1.5rem !default;
$font-1x: 2.125rem !default;
$font-2x: 2.25rem !default;
$font-3x: 3.75rem !default;
$font-4x: 5.625rem !default;

/* Line Height palette
    **************** */
$line-height-xs: 1rem !default;
$line-height-s: 1.125rem !default;
$line-height-m: 1.25rem !default;
$line-height-l: 1.375rem !default;
$line-height-xl: 1.5rem !default;
$line-height-xxl: 1.625rem !default;
$line-height-xxxl: 2.375rem !default;
$line-height-1x: 2.75rem !default;
$line-height-2x: 4rem !default;

/* Font weight variations
    ************************* */
$font-weight-regular: 500 !default;
$font-weight-medium: 700 !default;
$font-weight-bold: 900 !default;

/* Font Style variation
    ************************* */
$font-style-italic: italic !default;
